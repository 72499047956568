import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import get from "lodash/get";

import Container from "./Container";
import { Title42 } from "./Typography";
import HeaderBG from "./../images/header-background.png";
import ZSAIcon from "./../images/zsa-icon.png";
import ZSAIconFull from "./../images/zsa-icon-full.png";
import * as StyleVars from "./../styles/cssVariables";
import SearchBar from "./../components/SearchBar";

const Content = styled.div`
  background: url(${ZSAIcon});
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  @media (${StyleVars.mediaMobile}) {
    background: none;
    background-position-y: 40px;
    overflow: visible;
    position: relative;
    z-index: 2;
  }
`;

const Section = styled.h1`
  margin: 0;
  position: relative;
  background: url(${HeaderBG});
  background-size: 100% 100%;

  @media (${StyleVars.mediaMobile}) {
    padding: 0 40px;
    padding-bottom: 64px;

    &:before {
      content: "";
      background: url(${ZSAIconFull});
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: 90% 20px;
      z-index: 1;
    }
  }
`;

const Title = styled(Title42)`
  padding: 64px 0 8px;
  text-align: left;
  font-weight: 300;
  color: #fff;
  font-size: 42px;
  display: block;
  line-height: 1.3;
  margin: 0;
  @media (${StyleVars.mediaMobile}) {
    padding: 64px 0 64px;
  }
`;

const SearchBarContainer = styled.div`
  display: none;

  @media (${StyleVars.mediaMobile}) {
    display: block;
  }
`;

const Subtitle = styled.div`
  padding-bottom: 64px;
  font-family: "Merriweather";
  font-size: 30px;
  font-weight: normal;
  line-height: 40px;
  letter-spacing: -0.021em;
  text-align: left;
  color: #fff;
`;

const LineBreakAt = styled.span`
  display: inline-block; 
`;

export default function SectionTitle({ onSurprise, onSearch, title = "People. Not Keyboards", noSearch, numPeople }) {
  const CustomTitle = title
  return (
    <Section>
      <Container>
        <Content>
          <Title to="/">
            {typeof CustomTitle === "function" ? <CustomTitle /> : "People. Not Keyboards"}
          </Title>
          {numPeople && <Subtitle>
            <LineBreakAt>{numPeople} interviews</LineBreakAt>&nbsp;<LineBreakAt>since 2018</LineBreakAt>
          </Subtitle>}

          {!noSearch && (
            <SearchBarContainer>
              <SearchBar onSearch={onSearch} onSurprise={onSurprise} />
            </SearchBarContainer>
          )}
        </Content>
      </Container>
    </Section>
  );
}
