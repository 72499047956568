import React from "react";
import Section from "../components/SectionTitle";
import Layout from "../components/Layout";
import styled from "styled-components";
import Title from "../components/PageTitle";

const AppContent = styled.main`
  overflow: hidden;
  padding-bottom: 48px;
`;

const Link = styled.a`
  text-decoration: underline;
  color: #008cba;
  line-height: inherit;

  &:hover {
    color: #0078a0;
  }
`;

const Content = styled.div`
  font-family: "Inter", sans-serif;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  --x-height-multiplier: 0.375;
  --baseline-multiplier: 0.17;
  letter-spacing: 0.01rem;
  font-weight: 400;
  font-style: normal;
  font-size: 21px;
  line-height: 1.58;
  letter-spacing: -0.003em;
  text-rendering: optimizeLegibility;

  @media (max-width: 700px) {
    padding: 0 16px;
  }

  @media (max-width: 1024px) {
    padding: 0 16px;
  }

  p,
  ul {
    padding-bottom: 0.2rem;
    margin-bottom: 0;
    margin-top: 10px;

    line-height: 35px;
    font-size: 1.375rem;
    letter-spacing: -0.24px;
    font-weight: 400;

    @media (max-width: 700px) {
      font-size: 1.2rem;
      line-height: 30px;
    }
  }

  a {
    text-decoration: underline;
    color: #008cba;
    line-height: inherit;

    &:hover {
      color: #0078a0;
    }
  }
`;

const Wrapper = styled.div`
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 1100px) {
    padding: 0 16px;
  }

  @media (max-width: 1024px) {
    padding: 0 16px;
  }

  img {
    max-width: 100%;
  }
`;

class Index extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <AppContent>
          <Section />
          <Title title="Tell us about your setup!" />
          <Wrapper>
            <Content>
              <p>
                You know who’s missing from this lovely set of featured
                customers? <strong>You.</strong>
              </p>
              <p>
                That’s right &#8212; we want to know everything about you. Who
                you are, what you do, and what your setup looks like.
              </p>
              <p>
                If that sounds like fun, please{" "}
                <Link href="mailto:contact@ergodox-ez.com">drop us a line</Link>{" "}
                and we would absolutely love to interview you!
              </p>
              <p>
                All the best, <br /> Erez and the ErgoDox EZ team
              </p>
            </Content>
          </Wrapper>
        </AppContent>
      </Layout>
    );
  }
}

export default Index;
